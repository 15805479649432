import { useEffect, useState } from 'react';
import { IComment } from '../../../types/comment.type';

export const useCommentCount = (comments: IComment[] | null | undefined) => {
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    let count = 0;

    const countNestedComments = (
      nestedComments: IComment[] | null | undefined
    ) => {
      if (!nestedComments) return;

      for (const comment of nestedComments) {
        count++;
        countNestedComments(comment.comments);
      }
    };

    countNestedComments(comments);
    setCommentCount(count);
  }, [comments]);

  return commentCount;
};
