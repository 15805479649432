import { Loading } from '../../components/core';
import { useAppSelector } from '../../state';
import {
  selectCommunitiesSearchError,
  selectCommunitiesSearchLoading,
  selectCommunitiesSearchResults
} from '../../state/selectors/communitiesSelector';
import {
  selectSearchError,
  selectSearchLoading,
  selectSearchPapers
} from '../../state/selectors/papersSelector';
import {
  selectRequestAbortedForNew,
  selectSearchPageNum,
  selectSearchTerm,
  selectSearchType
} from '../../state/selectors/searchSelector';
import {
  selectTopicsSearchError,
  selectTopicsSearchLoading,
  selectTopicsSearchResults
} from '../../state/selectors/topicsSelector';
import { ICommonHubPaged } from '../../types/hubs.type';
import { IPaperPaged } from '../../types/papers.type';
import { ISearchType } from '../../types/search.type';

export const hasNoResults = (
  type: ISearchType,
  papers: IPaperPaged | null,
  topics: ICommonHubPaged | null,
  communities: ICommonHubPaged | null
): boolean => {
  switch (type) {
    case ISearchType.Papers:
      return !papers?.page || papers.page.length < 1;
    case ISearchType.Topics:
      return !topics?.page || topics.page.length < 1;
    case ISearchType.Communities:
      return !communities?.page || communities.page.length < 1;
    default:
      return true;
  }
};

interface SearchStatusHandlerProps {
  query: string;
  page: number;
  type: ISearchType;
  children: React.ReactNode;
}

export const SearchStatusHandler: React.FC<SearchStatusHandlerProps> = ({
  query,
  page,
  type,
  children
}: SearchStatusHandlerProps) => {
  const searchTerm = useAppSelector(selectSearchTerm);
  const searchPageNum = useAppSelector(selectSearchPageNum);
  const searchType = useAppSelector(selectSearchType);
  const searchErrorPapers = useAppSelector(selectSearchError);
  const searchErrorCommunities = useAppSelector(selectCommunitiesSearchError);
  const searchErrorTopics = useAppSelector(selectTopicsSearchError);

  const searchError =
    searchErrorPapers || searchErrorCommunities || searchErrorTopics;

  const searchLoadingPapers = useAppSelector(selectSearchLoading);
  const searchLoadingCommunities = useAppSelector(
    selectCommunitiesSearchLoading
  );
  const searchLoadingTopics = useAppSelector(selectTopicsSearchLoading);

  const searchLoading =
    searchLoadingPapers || searchLoadingCommunities || searchLoadingTopics;
  const requestAbortedForNew = useAppSelector(selectRequestAbortedForNew);

  const papers = useAppSelector(selectSearchPapers);
  const topics = useAppSelector(selectTopicsSearchResults);
  const communities = useAppSelector(selectCommunitiesSearchResults);

  const isOutdated =
    searchTerm !== query || searchPageNum !== page || searchType !== type;

  if (searchLoading || isOutdated || requestAbortedForNew) {
    return <Loading className="mt-5" />;
  }

  if (searchError && !requestAbortedForNew) {
    return (
      <div className="w-full max-w-3xl flex mt-5 px-4 justify-center items-center flex-grow mx-auto">
        We couldn&apos;t process your search at this minute. We&apos;re actively
        working on improving this site and will iron out any issues. In the
        meantime, please search for something different.
      </div>
    );
  }

  const noResults = hasNoResults(type, papers, topics, communities);

  if (noResults && !searchError && !searchLoading) {
    return (
      <div className="w-full max-w-3xl flex justify-center items-center flex-grow mx-auto mt-6">
        No results found
      </div>
    );
  }

  return <>{children}</>;
};
