import { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Paragraph,
  Flex,
  HeadingOne,
  HeadingTwo,
  Button
} from '../../../atoms';
import { texts } from '../../../text';
import {
  Checkbox,
  RequiredFieldLabel,
  TextareaLetterCount,
  Validation
} from '../../../components/form';
import TopicTagsInput from '../../../components/form/topicTagsInput';
import { InputLetterCount } from '../../../components/form/inputLetterCount';
import { Tag } from '../../../types/topic.type';

interface Props {
  topicTags: Tag[];
  selectedTopicTags: Tag[];
  removeTopic: (topicUrlKey: string) => void;
  filterTopics: (e: React.FormEvent<HTMLInputElement>) => Promise<void>;
  addTopic: (elem: string) => void;
  setCommunityName: (name: string) => void;
  communityName: string;
  description: string;
  setDescription: (description: string) => void;
  handleCreate: () => void;
  errors: Record<string, string>;
  inputTopicsValue: string;
}

const CreateCommunitySchema = Yup.object().shape({
  communityName: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  isChecked: Yup.boolean().oneOf([true], 'Required')
});

export const CreateCommunity: FC<Props> = ({
  topicTags,
  selectedTopicTags,
  removeTopic,
  filterTopics,
  addTopic,
  setCommunityName,
  setDescription,
  handleCreate,
  inputTopicsValue,
  communityName,
  description
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const formik = useFormik({
    initialValues: {
      communityName,
      description,
      isChecked
    },
    validationSchema: CreateCommunitySchema,
    onSubmit: () => {
      handleCreate();
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleNameChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      setCommunityName(e.target.value);
    };
  }, [formik, setCommunityName]);

  const handleDescriptionChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      formik.handleChange(e);
      setDescription(e.target.value);
    };
  }, [formik, setDescription]);

  return (
    <form
      className="w-full mx-auto register-form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      noValidate
    >
      <main className="page-content max-w-2/3">
        <Flex direction="flex-row m-auto">
          <Flex className="m-auto max-w-3xl">
            <HeadingOne color="black">{texts.createCommunity}</HeadingOne>
            <Paragraph className="mb-0" size="xs" color="black">
              {texts.createCommunityDescription}
            </Paragraph>

            <HeadingTwo className="font-black">
              <RequiredFieldLabel label={texts.communityName} />
            </HeadingTwo>
            <Paragraph className="mb-4" size="xs" color="black">
              {texts.communityNameDescription}
            </Paragraph>
            <InputLetterCount
              value={formik.values.communityName}
              setValue={handleNameChange}
              onBlur={formik.handleBlur}
              maxLength={180}
              placeholder={texts.communityName}
              name="communityName"
              error={
                formik.touched.communityName && formik.errors.communityName
                  ? formik.errors.communityName
                  : undefined
              }
              touched={formik.touched.communityName}
            />
            <HeadingTwo className="font-black">
              <RequiredFieldLabel label={texts.description} />
            </HeadingTwo>
            <Paragraph className="mb-4" size="xs" color="black">
              {texts.descriptionExplain}
            </Paragraph>
            <TextareaLetterCount
              value={formik.values.description}
              setValue={handleDescriptionChange}
              onBlur={formik.handleBlur}
              maxLength={1024}
              placeholder={texts.description}
              name="description"
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : undefined
              }
              touched={formik.touched.description}
            />
            <HeadingTwo>{texts.topicTags}</HeadingTwo>
            <TopicTagsInput
              topicTags={topicTags}
              selectedTopicTags={selectedTopicTags}
              onAddTopic={addTopic}
              filterTopics={filterTopics}
              inputTopicsValue={inputTopicsValue}
              onRemoveTopic={removeTopic}
              explanationText={texts.topicTagsExplain}
              maxLimit={100}
            />
            <div className="mb-8">
              <Checkbox
                label={texts.checkbox}
                required={true}
                checked={formik.values.isChecked}
                onChange={(e) => {
                  formik.setFieldValue('isChecked', e.target.checked);
                  setIsChecked(e.target.checked);
                }}
                className={`${
                  formik.touched.isChecked && formik.errors.isChecked
                    ? 'border-error-500'
                    : 'border-gray-500'
                }`}
              ></Checkbox>
              {formik.touched.isChecked && formik.errors.isChecked && (
                <Validation message={formik.errors.isChecked} />
              )}
            </div>
            <div className="flex justify-end">
              <Button
                className="mt-4 bg-gradient-to-r from-primaryLight to-primaryDefault inline fitContent desktop:px-20 laptop:px-20 px-10 text-small uppercase py-1 font-medium"
                rounded="md"
                size="imd"
                type="submit"
              >
                {texts.createCommunityButton}
              </Button>
            </div>
          </Flex>
        </Flex>
      </main>
    </form>
  );
};
