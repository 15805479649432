import { Button } from '../form';
import {
  CommunitySortMethod,
  DiscussionSortMethod,
  PaperCommunitySortMethod,
  PaperTopicSortMethod,
  SORT_METHOD_DISPLAY_NAMES,
  TopicSortMethod,
  userPostsSortMethod
} from '../../utils/sortingConstants';

export type GenericSortMethod =
  | DiscussionSortMethod
  | PaperCommunitySortMethod
  | PaperTopicSortMethod
  | TopicSortMethod
  | CommunitySortMethod
  | userPostsSortMethod;

export interface SortingButtonsProps<T> {
  sortMethods: Record<string, T>;
  currentSortMethod: T;
  onSortMethodChange: (method: T) => () => void;
}

export const SortingButtons = <T extends GenericSortMethod>({
  sortMethods,
  currentSortMethod,
  onSortMethodChange
}: SortingButtonsProps<T>) => {
  const formatLabel = (methodValue: T): string => {
    const methodKey = String(
      methodValue
    ) as keyof typeof SORT_METHOD_DISPLAY_NAMES;
    return SORT_METHOD_DISPLAY_NAMES[methodKey] || methodKey;
  };

  // Function to determine if the button should be disabled based on '_disabled' suffix
  const isDisabled = (methodValue: string): boolean => {
    return methodValue.endsWith('_disabled');
  };

  return (
    <div className="w-full px-4">
      <div className="max-w-3xl-plusrem flex w-full ml-auto mr-auto">
        <div
          className="flex flex-row justify-center 
          laptop:justify-start desktop:justify-start tablet:justify-start flex-wrap pt-5 w-full"
        >
          <div className="flex flex-wrap justify-center tablet:justify-start laptop:justify-start w-full">
            {Object.entries(sortMethods).map(([methodKey, methodValue]) => (
              <Button
                key={methodKey}
                onClick={onSortMethodChange(methodValue)}
                outline={currentSortMethod !== methodValue}
                className="py-2 mr-1 ml-1 mb-2 sortingBtn text-small"
                disabled={isDisabled(methodValue)}
              >
                {formatLabel(methodValue)}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
