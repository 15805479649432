import { useEffect } from 'react';
import {
  deleteComment,
  editComment,
  getCommentsWithDeleted,
  postComment
} from '../../../state/actions/commentsActions';
import { useAppDispatch, useAppSelector } from '../../../state';
import { useParams } from 'react-router-dom';
import { getSectionIdForPaper } from '../../../state/actions/paperActions';
import { clearComments } from '../../../state/slices/commentsReducer';

export function useCommentsPaper() {
  const dispatch = useAppDispatch();
  const { id: paperId } = useParams<{ id: string }>();
  const paper = useAppSelector((state) => state.paper.paper);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );

  useEffect(() => {
    if (paper?.commentSectionId) {
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: paper.commentSectionId,
          order: commentsSortMethod
        })
      );
    }
    return () => {
      dispatch(clearComments());
    };
  }, [dispatch, paper?.commentSectionId, commentsSortMethod]);

  const handleReply = async (text: string, parentId: number | null) => {
    if (paper && paper.commentSectionId) {
      await dispatch(
        postComment({
          commentSectionId: paper.commentSectionId,
          body: text,
          parentCommentId: parentId
        })
      );
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: paper.commentSectionId,
          order: commentsSortMethod
        })
      );
    } else if (paperId) {
      const commentSectionId = await dispatch(
        getSectionIdForPaper({ paperId: Number(paperId) })
      ).unwrap();
      await dispatch(
        postComment({ commentSectionId, body: text, parentCommentId: parentId })
      );
      dispatch(
        getCommentsWithDeleted({ commentSectionId, order: commentsSortMethod })
      );
    }
  };

  const handleEdit = async (commentId: number, text: string) => {
    await dispatch(editComment({ commentId, body: text }));
    // refresh comments list after editing
    paper?.commentSectionId &&
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: paper.commentSectionId,
          order: commentsSortMethod
        })
      );
  };

  const handleDelete = async (commentId: number) => {
    await dispatch(deleteComment({ commentId }));
    // refresh comments list after deletion
    paper?.commentSectionId &&
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: paper.commentSectionId,
          order: commentsSortMethod
        })
      );
  };

  return { handleReply, handleEdit, handleDelete };
}
