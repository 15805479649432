import { FC, useState } from 'react';
import { PapersList } from './papers-list';
import { DiscussionsList } from './discussions-list';
import { useAppSelector } from '../../state';
import { CommunityTitlePlace } from '../../routes/community/communityTitlePlace';
import { selectCommunityData } from '../../state/selectors/communitySelector';
import { PapersDiscussionsTabs } from './papersDiscussionsTabs';
import { DeletedUserPostsListCommunity } from '../userPosts';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { AdminSidebar } from '../adminSidebar';

export const PapersDiscussionsTabsCommunity: FC = () => {
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const [showDeletedPosts, setShowDeletedPosts] = useState(false);
  const communityData = useAppSelector(selectCommunityData);

  const radioState = useAppSelector(
    (state) => state.tabs.papersDiscussionsRadioState
  );

  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full w-full">
        <PapersDiscussionsTabs
          contentUrlKey={`community/${communityData?.urlKey}`}
          hidePapersTab={(communityData?.topicTags?.length ?? 0) === 0}
        />
        <div className="maincont flex flex-row w-full">
          <div className="pagepart w-full h-full">
            <CommunityTitlePlace />
            <div className="flex w-full flex-row h-full">
              <div className="flex w-full flex-col">
                {isUserAdmin && showDeletedPosts ? (
                  <DeletedUserPostsListCommunity />
                ) : radioState === 'papers' ? (
                  <PapersList isCommunity={true} />
                ) : (
                  <DiscussionsList isCommunity={true} />
                )}
              </div>
            </div>
          </div>
          {isUserAdmin && (
            <AdminSidebar
              showDeletedPosts={showDeletedPosts}
              setShowDeletedPosts={setShowDeletedPosts}
            />
          )}
        </div>
      </div>
    </div>
  );
};
