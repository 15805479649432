import { useCommentCount } from './hooks/useCommentCount';
import { useAppDispatch, useAppSelector } from '../../state';
import {
  COMMENT_SORT_METHODS,
  CommentSortMethod
} from '../../utils/sortingConstants';
import { setCommentSortMethod } from '../../state/actions/sortingActions';
import { IComment } from '../../types/comment.type';

interface CountCommentsOrderProps {
  comments: IComment[] | null;
}

export const CountCommentsOrder: React.FC<CountCommentsOrderProps> = ({
  comments
}: CountCommentsOrderProps) => {
  const commentCount = useCommentCount(comments);
  const dispatch = useAppDispatch();
  const handleSortingCommentsChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const method = e.target.value as CommentSortMethod; // Ensure the value is treated as CommentSortMethod
    dispatch(setCommentSortMethod(method));
  };
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );

  return (
    <div className="flex items-center justify-end w-full">
      {comments !== null ? (
        <div className="opacity-50 text-base mr-4">{commentCount} comments</div>
      ) : (
        ''
      )}
      <div className="bg-white pl-4 pr-2 py-2 flex justify-center items-center text-sm">
        <span className="whitespace-no-wrap">Sort by</span>
        <select
          onChange={handleSortingCommentsChange}
          className="w-full flex pl-1 pr-6 py-1 text-primaryDefault focus:outline-none text-sm selectOrder blackArrow nowrap"
          value={commentsSortMethod}
        >
          <option value={COMMENT_SORT_METHODS.CREATED_DESC}>Most Recent</option>
          <option value={COMMENT_SORT_METHODS.CREATED_ASC}>Oldest</option>
        </select>
      </div>
    </div>
  );
};
