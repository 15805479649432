import { FC, useMemo, useState } from 'react';

import { HeadingOne } from '../../atoms';
import { EditPostIcon } from '../../svgs/EditPost';
import { DeletePostIcon } from '../../svgs/DeletePost';
import { useAppDispatch, useAppSelector } from '../../state';
import { openModal } from '../../state/actions/modalsActions';
import {
  selectUserPost,
  selectUserPostGetLoading
} from '../../state/selectors/userPostSelector';
import { Loading } from '../../components/core';
import { useNavigate } from 'react-router-dom';
import { useDeletePost } from './hooks/useDeletePost';
import { formatDate } from '../../utils/userPostHelper';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import {
  ConfirmationModalWrapper,
  NotificationModalWrapper
} from '../../components/modals';
import { Tooltip } from '../../components/core/tooltip';
import { createLink } from '../../utils/helperFunctions';
import { useAuth } from '../../state/hooks/useAuth';

export const UserPostContainer: FC = () => {
  const navigate = useNavigate();
  const postData = useAppSelector(selectUserPost);
  const loading = useAppSelector(selectUserPostGetLoading);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleOpenModal = (modalName: string) => {
    dispatch(openModal(modalName));
  };

  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user } = useAuth();
  const isUserCreator = useMemo(() => {
    return user && postData?.createdByUser?.id
      ? Number(user.id) === Number(postData.createdByUser?.id)
      : false;
  }, [user, postData?.createdByUser?.id]);

  const deletePostHandler = useDeletePost(
    () => {
      setIsConfirmationModalOpen(false);
      setIsNotificationModalOpen(true);
    },
    (error) => {
      // eslint-disable-next-line no-console
      console.log('Error deleting post:', error);
    }
  );

  const handleDeletePost = () => {
    if (postData?.id) {
      deletePostHandler({ postId: postData.id });
    }
  };

  const handleNotificationClose = () => {
    setIsNotificationModalOpen(false);
    navigate(createLink('/communities'));
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="flex flex-row items-start justify-between mb-2">
        <div className="">
          <HeadingOne color="black" className="mb-0">
            {postData?.title}
          </HeadingOne>
        </div>
        <div className="flex flex-row">
          {(isUserAdmin || isUserCreator) && postData?.state === 1 && (
            <div
              onClick={() => handleOpenModal('updatePost')}
              className="mr-1 cursor-pointer"
            >
              <Tooltip className="tooltipSm" text={'Edit'}>
                <EditPostIcon />
              </Tooltip>
            </div>
          )}
          {(isUserAdmin || isUserCreator) && postData?.state === 1 && (
            <div
              className="cursor-pointer"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              <Tooltip className="tooltipSm" text={'Delete'}>
                <DeletePostIcon className="cursor-pointer" />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="text-yellow text-sm mb-6">#Po[{postData?.id}]</div>
      <div className="text-sm mb-4">
        {postData?.createdUtc && (
          <div className="opacity-50">
            Created&nbsp;
            {formatDate(postData.createdUtc)}
            {postData?.createdByUser?.displayName && (
              <>
                <span className=""> by </span>
                <span className="text-yellow text-sm">
                  {postData?.createdByUser?.displayName}
                </span>
              </>
            )}
          </div>
        )}
        {postData?.lastModifiedUtc && (
          <div className="opacity-50 mt-2">
            Edited&nbsp;
            {formatDate(postData.lastModifiedUtc)}
            {postData?.updatedByUser?.displayName && (
              <>
                <span className=""> by </span>
                <span className="text-yellow text-sm">
                  {postData?.updatedByUser?.id !== postData?.createdByUser?.id
                    ? 'moderator'
                    : postData?.updatedByUser?.displayName}
                </span>
              </>
            )}
          </div>
        )}
        {postData?.deletedUtc && postData?.deletedByUser && (
          <div className="opacity-50 mt-2">
            This post was deleted by{' '}
            {postData?.deletedByUser?.id !== postData?.createdByUser?.id
              ? 'moderator '
              : 'user '}
            on: {formatDate(postData.deletedUtc)}
          </div>
        )}
      </div>
      {postData?.state !== 2 ? (
        <div className="ql-snow">
          <div
            className="ql-editor ql-editor--show whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: postData?.markdown || '' }}
          ></div>
        </div>
      ) : (
        <div className="">
          This post was deleted by&nbsp;
          {postData?.deletedByUser?.id !== postData?.createdByUser?.id
            ? 'moderator'
            : 'user'}
          .
        </div>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModalWrapper
          isOpen={isConfirmationModalOpen}
          title="Confirm Deletion"
          message="Are you sure you want to delete this post?"
          onConfirm={handleDeletePost}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
      {isNotificationModalOpen && (
        <NotificationModalWrapper
          isOpen={isNotificationModalOpen}
          title="Success"
          message="Post deleted successfully."
          onClose={handleNotificationClose}
        />
      )}
    </>
  );
};
