import { useEffect, useState, useMemo } from 'react';
import { useGetCommentsbyIdData } from '../../../api/dataLayer/commentSections';
import { IPaper } from '../../../types/paper.type';
import { useCommentCount } from '../../discussion';
import { ApiPaperPreview } from '../../../api/oipub-api';
import { HasLinks } from '../../../svgs';
import { createLink } from '../../../utils/helperFunctions';

interface UsePaperDataProps {
  paper: IPaper;
  handleTopicTagClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    urlKey: string
  ) => void;
}

export const usePaperPreviewData = ({
  paper,
  handleTopicTagClick
}: UsePaperDataProps) => {
  const [commentCount, setCommentCount] = useState<number | null>(null);

  // Get comment data and count
  const { commentsData } = useGetCommentsbyIdData(
    'commentSectionId' in paper ? paper.commentSectionId : null
  );
  const computedCommentCount = useCommentCount(commentsData);

  // Update the comment count based on the paper or preview
  useEffect(() => {
    if ('commentSectionId' in paper) {
      setCommentCount(computedCommentCount);
    } else {
      const previewPaper = paper as ApiPaperPreview;
      if ('numComments' in previewPaper) {
        setCommentCount(previewPaper.numComments as number);
      }
    }
  }, [paper, computedCommentCount]);

  // Memoized tags/keywords rendering logic
  const renderedTopicsKeywords = useMemo(() => {
    const keywords: JSX.Element[] = [];
    const showTopicsKeywordsCount = 2;
    let i = 0;

    if (paper.tags) {
      for (const tag of paper.tags.slice(0, showTopicsKeywordsCount)) {
        if (keywords.length < showTopicsKeywordsCount) {
          if (tag && tag.urlKey && tag.id) {
            keywords.push(
              <span
                role="button"
                onClick={(e) => handleTopicTagClick(e, tag.urlKey!)}
                className="text-ssm mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline leading-none mr-1 mb-2 transition-colors duration-200 text-center"
                key={tag.id}
                style={{ cursor: 'pointer' }}
              >
                {tag.name}
                {i < paper.tags.length - 1 ? ', ' : ' '}
              </span>
            );
            i++;
          }
        }
      }
    }

    return keywords;
  }, [paper.tags, handleTopicTagClick]);

  // Memoized links availability rendering logic
  const renderedLinksAvailable = useMemo(() => {
    if (paper.links && paper.links.length > 0) {
      return (
        <div
          className="text-primary-500 inline-block w-4 h-4 ml-2"
          title="Links available"
        >
          <HasLinks className="link_avaliable" />
        </div>
      );
    }
    return null;
  }, [paper.links]);

  // Full URL generation logic
  const fullUrl = useMemo(() => {
    let url = createLink('/paper');
    if (paper?.id) {
      url += `/${paper.id}`;
    }
    const currentUrl = window.location.origin;
    return currentUrl + url;
  }, [paper?.id]);

  const numOfTopicsKeywords = paper.tags ? paper.tags.length : 0;

  // Show authors logic
  const authorsDisplay = useMemo(() => {
    if (!paper?.authors) {
      return '';
    }

    return `By&nbsp;${
      paper.authors.length > 8
        ? `${paper.authors
            .slice(0, 8)
            .map((author) => author.name)
            .join('; ')}; ... and ${
            paper.authors.length - 8
          } other${paper.authors.length - 8 > 1 ? 's' : ''}`
        : paper.authors.map((author) => author.name).join('; ')
    }`;
  }, [paper?.authors]);

  return {
    commentCount,
    renderedLinksAvailable,
    renderedTopicsKeywords,
    fullUrl,
    numOfTopicsKeywords,
    authorsDisplay
  };
};
