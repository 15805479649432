import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaper } from '../../types/paper.type';
import { env } from '../../env';

// Fetching paper by ID
export const getPaperById = createAsyncThunk<IPaper, number>(
  'get/getPaperById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(`${env.apiUrl}/api/v1/papers/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);

// Fetching paper by slug
export const getPaperBySlug = createAsyncThunk<
  IPaper,
  { yearMonth: number; slug: string; hash: number }
>(
  'get/getPaperBySlug',
  async ({ yearMonth, slug, hash }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/papers/${yearMonth}/${slug}/${hash}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);

export const getSectionIdForPaper = createAsyncThunk<
  number,
  { paperId: number }
>('get/getSectionIdForPaper', async ({ paperId }, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${env.apiUrl}/api/v1/papers/${paperId}/commentsection/id`,
      {
        method: 'GET'
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const commentSectionId: number = await response.json();
    return commentSectionId;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue(
        'An unknown error occurred while fetching the comment section ID'
      );
    }
  }
});

export const getPaperReferenceCitationById = createAsyncThunk<IPaper, number>(
  'get/getPaperReferenceCitationById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/papers/${id}/extended`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);
