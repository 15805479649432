import { useEffect } from 'react';
import {
  deleteComment,
  editComment,
  getCommentsWithDeleted,
  postComment
} from '../../../state/actions/commentsActions';
import { useAppDispatch, useAppSelector } from '../../../state';
import { clearComments } from '../../../state/slices/commentsReducer';
import { selectUserPost } from '../../../state/selectors/userPostSelector';

export function useCommentsUserPost() {
  const dispatch = useAppDispatch();
  const postData = useAppSelector(selectUserPost);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );

  useEffect(() => {
    if (postData && postData.commentSectionId) {
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: postData.commentSectionId,
          order: commentsSortMethod
        })
      );
    }
    // Clear comments when the component unmounts or the commentSectionId changes
    return () => {
      dispatch(clearComments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, postData?.commentSectionId, commentsSortMethod]);

  const handleReply = async (text: string, parentId: number | null) => {
    if (postData && postData.commentSectionId) {
      await dispatch(
        postComment({
          commentSectionId: postData.commentSectionId,
          body: text,
          parentCommentId: parentId
        })
      );

      // Reload comments after posting a reply
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: postData.commentSectionId,
          order: commentsSortMethod
        })
      );
    }
  };

  const handleEdit = async (commentId: number, body: string) => {
    if (postData && postData.commentSectionId) {
      await dispatch(editComment({ commentId, body }));

      dispatch(
        getCommentsWithDeleted({
          commentSectionId: postData.commentSectionId,
          order: commentsSortMethod
        })
      );
    }
  };

  const handleDelete = async (commentId: number) => {
    if (postData && postData.commentSectionId) {
      await dispatch(deleteComment({ commentId }));
      dispatch(
        getCommentsWithDeleted({
          commentSectionId: postData.commentSectionId,
          order: commentsSortMethod
        })
      );
    }
  };

  return { handleReply, handleEdit, handleDelete };
}
