import {
  CommentSortMethod,
  CommunitySortMethod,
  DiscussionSortMethod,
  PaperCommunitySortMethod,
  PaperTopicSortMethod,
  TopicSortMethod,
  userPostsSortMethod
} from '../../utils/sortingConstants';

export const SET_DISCUSSION_SORT_METHOD = 'SET_DISCUSSION_SORT_METHOD' as const;
export const SET_PAPER_COMMUNITY_SORT_METHOD =
  'PAPER_COMMUNITY_SORT_METHOD' as const;
export const SET_PAPER_TOPIC_SORT_METHOD = 'PAPER_TOPIC_SORT_METHOD' as const;
export const SET_TOPIC_SORT_METHOD = 'SET_TOPIC_SORT_METHOD' as const;
export const SET_COMMUNITY_SORT_METHOD = 'SET_COMMUNITY_SORT_METHOD' as const;
export const SET_COMMENT_SORT_METHOD = 'SET_COMMENT_SORT_METHOD' as const;
export const SET_USERPOSTS_SORT_METHOD = 'SET_USERPOSTS_SORT_METHOD';

export const setUserPostsSortMethod = (sortMethod: userPostsSortMethod) => ({
  type: SET_USERPOSTS_SORT_METHOD,
  payload: sortMethod
});

export const setDiscussionSortMethod = (sortMethod: DiscussionSortMethod) => ({
  type: SET_DISCUSSION_SORT_METHOD,
  payload: sortMethod
});

export const setPaperCommunitySortMethod = (
  sortMethod: PaperCommunitySortMethod
) => ({
  type: SET_PAPER_COMMUNITY_SORT_METHOD,
  payload: sortMethod
});

export const setPaperTopicSortMethod = (sortMethod: PaperTopicSortMethod) => ({
  type: SET_PAPER_TOPIC_SORT_METHOD,
  payload: sortMethod
});

export const setTopicSortMethod = (sortMethod: TopicSortMethod) => ({
  type: SET_TOPIC_SORT_METHOD,
  payload: sortMethod
});

export const setCommunitySortMethod = (sortMethod: CommunitySortMethod) => ({
  type: SET_COMMUNITY_SORT_METHOD,
  payload: sortMethod
});

export const setCommentSortMethod = (sortMethod: CommentSortMethod) => ({
  type: SET_COMMENT_SORT_METHOD,
  payload: sortMethod
});

export interface SetDiscussionSortMethodAction {
  type: typeof SET_DISCUSSION_SORT_METHOD;
  payload: DiscussionSortMethod;
}

export interface setUserPostsSortMethodAction {
  type: typeof SET_USERPOSTS_SORT_METHOD;
  payload: userPostsSortMethod;
}

export interface SetPaperCommunitySortMethodAction {
  type: typeof SET_PAPER_COMMUNITY_SORT_METHOD;
  payload: PaperCommunitySortMethod;
}

export interface SetPaperTopicSortMethodAction {
  type: typeof SET_PAPER_TOPIC_SORT_METHOD;
  payload: PaperTopicSortMethod;
}

export interface SetTopicSortMethodAction {
  type: typeof SET_TOPIC_SORT_METHOD;
  payload: TopicSortMethod;
}

export interface SetCommunitySortMethodAction {
  type: typeof SET_COMMUNITY_SORT_METHOD;
  payload: CommunitySortMethod;
}

export interface SetCommentSortMethodAction {
  type: typeof SET_COMMENT_SORT_METHOD;
  payload: CommentSortMethod;
}

export type SortingActions =
  | SetDiscussionSortMethodAction
  | SetPaperCommunitySortMethodAction
  | SetPaperTopicSortMethodAction
  | SetTopicSortMethodAction
  | SetCommunitySortMethodAction
  | SetCommentSortMethodAction
  | setUserPostsSortMethodAction;
