import { FC, useMemo, useState } from 'react';
import { Flex, HeadingOne } from '../../atoms';
import { EditPostIcon } from '../../svgs/EditPost';
import { DeletePostIcon } from '../../svgs/DeletePost';
import { useAppDispatch, useAppSelector } from '../../state';
import { openModal } from '../../state/actions/modalsActions';
import { CommunityState } from '../../api';
import { useNavigate } from 'react-router-dom';
import { texts } from '../../text';
import { selectCommunityData } from '../../state/selectors/communitySelector';
import { useDeleteCommunity } from './hooks/useDeleteCommunity';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import {
  ConfirmationModalWrapper,
  NotificationModalWrapper
} from '../../components/modals';
import { Tooltip } from '../../components/core/tooltip';
import { createLink } from '../../utils/helperFunctions';
import { useAuth } from '../../state/hooks/useAuth';
import {
  selectPapers,
  selectPapersLoading
} from '../../state/selectors/papersSelector';
import { QuestionMark } from '../../svgs';

interface CommunityTitlePlaceProps {}

export const CommunityTitlePlace: FC<CommunityTitlePlaceProps> = ({}) => {
  const communityData = useAppSelector(selectCommunityData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleOpenModal = (modalName: string) => {
    dispatch(openModal(modalName));
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const { user } = useAuth();
  const isUserCreator = useMemo(() => {
    return user && communityData?.createdByUser?.id
      ? Number(user.id) === Number(communityData.createdByUser.id)
      : false;
  }, [user, communityData?.createdByUser?.id]);

  const handleSuccess = () => {
    setIsConfirmationModalOpen(false);
    setIsNotificationModalOpen(true);
  };

  const handleError = (error: any) => {
    // eslint-disable-next-line no-console
    console.log('Error deleting community:', error);
  };

  const deleteCommunity = useDeleteCommunity(handleSuccess, handleError);

  const handleConfirmDelete = () => {
    if (communityData?.urlKey) {
      deleteCommunity({ urlKey: communityData.urlKey });
    }
  };

  const handleNotificationClose = () => {
    setIsNotificationModalOpen(false);
    navigate(createLink('/communities'));
  };

  const loading = useAppSelector(selectPapersLoading);
  const papersCount = useAppSelector(selectPapers).count;

  return (
    <main className="page-content pt-10 pb-8 px-4">
      <div className="max-w-3xl m-auto">
        <Flex direction="flex-row w-full">
          <Flex className="w-full">
            <div className="flex flex-row items-start justify-between mb-2">
              <div className="">
                <HeadingOne color="black" className="mb-0">
                  {communityData?.name}
                  {papersCount && !loading ? (
                    <Tooltip
                      className="tooltipTitle"
                      text={papersCount + ' papers in the community'}
                    >
                      <QuestionMark />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </HeadingOne>
              </div>
              {communityData?.state === CommunityState.Active && (
                <div className="flex flex-row">
                  {(isUserAdmin || isUserCreator) && (
                    <div
                      onClick={() => handleOpenModal('updateCommunity')}
                      className="mr-1 cursor-pointer"
                    >
                      <Tooltip className="tooltipSm" text={'Edit'}>
                        <EditPostIcon />
                      </Tooltip>
                    </div>
                  )}
                  {isUserAdmin && (
                    <div
                      className="cursor-pointer"
                      onClick={() => setIsConfirmationModalOpen(true)}
                    >
                      <Tooltip className="tooltipSm" text={'Delete'}>
                        <DeletePostIcon className="cursor-pointer" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="text-primary-500 text-sm">
              #C[{communityData?.name}]
            </div>
            {communityData?.state !== CommunityState.Active && (
              <div className="text-sm mt-10">{texts.disabledCommunity}</div>
            )}
          </Flex>
        </Flex>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModalWrapper
          isOpen={isConfirmationModalOpen}
          title="Confirm Deletion"
          message="Are you sure you want to delete this community?"
          onConfirm={handleConfirmDelete}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
      {isNotificationModalOpen && (
        <NotificationModalWrapper
          isOpen={isNotificationModalOpen}
          title="Success"
          message="Community deleted successfully."
          onClose={handleNotificationClose}
        />
      )}
    </main>
  );
};
