import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunitySummaryPaged } from '../../types/communities.type';
import {
  getCommunitiesData,
  getLandingCommunitiesData,
  searchCommunities
} from '../actions/communitiesActions';
import { ICommonHubPaged } from '../../types/hubs.type';

interface CommunitiesState {
  communities: ICommunitySummaryPaged | null;
  loading: boolean;
  error: string | null;
  landingCommunities: ICommunitySummaryPaged | null;
  landingLoading: boolean;
  landingError: string | null;
  searchResults: ICommonHubPaged | null;
  searchLoading: boolean;
  searchError: string | null;
  requestAbortedForNew: boolean;
}

const initialState: CommunitiesState = {
  communities: null,
  loading: false,
  error: null,
  landingCommunities: null,
  landingLoading: false,
  landingError: null,
  searchResults: null,
  searchLoading: false,
  searchError: null,
  requestAbortedForNew: false
};

const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCommunitiesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCommunitiesData.fulfilled,
        (state, action: PayloadAction<ICommunitySummaryPaged>) => {
          state.communities = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCommunitiesData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch communities';
        state.loading = false;
      })
      .addCase(getLandingCommunitiesData.pending, (state) => {
        state.landingLoading = true;
        state.landingError = null;
      })
      .addCase(
        getLandingCommunitiesData.fulfilled,
        (state, action: PayloadAction<ICommunitySummaryPaged>) => {
          state.landingCommunities = action.payload;
          state.landingLoading = false;
        }
      )
      .addCase(getLandingCommunitiesData.rejected, (state, action) => {
        state.landingError =
          action.error.message || 'Failed to fetch communities';
        state.landingLoading = false;
      })
      .addCase(searchCommunities.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
      })
      .addCase(
        searchCommunities.fulfilled,
        (state, action: PayloadAction<ICommonHubPaged>) => {
          state.searchResults = action.payload;
          state.searchLoading = false;
        }
      )
      .addCase(searchCommunities.rejected, (state, action) => {
        state.searchLoading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.error =
            action.error.message || 'Failed to fetch topics/communities';
        }
      });
  }
});

export const communitiesReducer = communitiesSlice.reducer;
