import { useState, useEffect, FormEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { useGetCommunitiesData } from '../../../community/hooks/useGetCommunitiesData';
import { selectCommunitiesSearchResults } from '../../../../state/selectors/communitiesSelector';
import { notEmpty } from '../../../../utils/helperFunctions';
import { searchCommunities } from '../../../../state/actions/communitiesActions';
import { Tag } from '../../updatePostV2/updatePostContainer';

export const useCommunitiesFormData = () => {
  const { communities } = useGetCommunitiesData({
    page: 1,
    pageSize: 10,
    communityType: 1
  });
  const dispatch = useAppDispatch();
  const searchResultsCommunities = useAppSelector(
    selectCommunitiesSearchResults
  );
  const [selectedCommunityTags, setSelectedCommunityTags] = useState<Tag[]>([]);
  const [initialCommunityTags, setInitialCommunityTags] = useState<Tag[]>([]);
  const [searchResultsCommunityTags, setSearchResultsCommunityTags] = useState<
    Tag[]
  >([]);
  const [availableCommunityTags, setAvailableCommunityTags] = useState<Tag[]>(
    []
  );
  const [inputCommunityTags, setInputCommunityTags] = useState('');

  const { selectedCommunityOrTopic } = useAppSelector(
    (state) => state.modal.createPost
  );
  const [
    isInitialCommunityManuallyRemoved,
    setIsInitialCommunityManuallyRemoved
  ] = useState(false);

  // Fetch and set all community tags when communities data changes
  useEffect(() => {
    const fullCommunityTags =
      communities?.page
        ?.map((community) => ({
          name: community?.name || '',
          urlKey: community?.urlKey || ''
        }))
        .filter(notEmpty) || [];
    setInitialCommunityTags(fullCommunityTags);
    updateAvailableCommunityTags(fullCommunityTags, selectedCommunityTags);
  }, [communities, selectedCommunityTags]);

  // Add the selectedCommunityOrTopic to selectedCommunityTags if it's a community
  useEffect(() => {
    if (
      selectedCommunityOrTopic?.entityType === 'community' &&
      !isInitialCommunityManuallyRemoved
    ) {
      const { name, urlKey } = selectedCommunityOrTopic;

      // Add only if it's not already selected
      if (!selectedCommunityTags.some((tag) => tag.urlKey === urlKey)) {
        setSelectedCommunityTags((prev) => [...prev, { name, urlKey }]);
      }
    }
  }, [
    selectedCommunityOrTopic,
    selectedCommunityTags,
    isInitialCommunityManuallyRemoved
  ]);

  // Update search results when searchResultsCommunities changes
  useEffect(() => {
    if (searchResultsCommunities?.page) {
      const searchedCommunityTags = searchResultsCommunities.page
        ?.map((community) => ({
          name: community?.name || '',
          urlKey: community?.urlKey || ''
        }))
        .filter(notEmpty);
      setSearchResultsCommunityTags(searchedCommunityTags || []);
      if (inputCommunityTags.length >= 3) {
        updateAvailableCommunityTags(
          searchedCommunityTags,
          selectedCommunityTags
        );
      }
    }
  }, [searchResultsCommunities, selectedCommunityTags, inputCommunityTags]);

  // Update available communities to exclude already selected communities
  const updateAvailableCommunityTags = (
    allTags: Tag[],
    selectedTags: Tag[]
  ) => {
    setAvailableCommunityTags(
      allTags.filter(
        (tag) =>
          !selectedTags.some((selectedTag) => selectedTag.urlKey === tag.urlKey)
      )
    );
  };

  // Remove a community from selected communities and add it back to the appropriate list
  const removeCommunity = (communityUrlKey: string) => {
    const newList = selectedCommunityTags.filter(
      (community) => community.urlKey !== communityUrlKey
    );
    setSelectedCommunityTags(newList);

    // Mark the community as manually removed if it's the one that was auto-added
    if (selectedCommunityOrTopic?.urlKey === communityUrlKey) {
      setIsInitialCommunityManuallyRemoved(true);
    }

    if (inputCommunityTags.length >= 3) {
      // When in search mode, update available communities based on search results
      updateAvailableCommunityTags(searchResultsCommunityTags, newList);
    } else {
      // When not in search mode, update available communities based on initial load
      updateAvailableCommunityTags(initialCommunityTags, newList);
    }
  };

  // Add a community to selected communities and remove it from the appropriate list
  const addCommunity = (communityUrlKey: string) => {
    const communityToAdd = availableCommunityTags.find(
      (community) => community.urlKey === communityUrlKey
    );
    if (
      communityToAdd &&
      !selectedCommunityTags.some(
        (community) => community.urlKey === communityUrlKey
      )
    ) {
      setSelectedCommunityTags([communityToAdd, ...selectedCommunityTags]);
      setAvailableCommunityTags((prev) =>
        prev.filter((tag) => tag.urlKey !== communityUrlKey)
      );
    }
  };

  // Filter communities based on input value
  const filterCommunities = async (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setInputCommunityTags(newValue);

    if (newValue.length >= 3) {
      try {
        await dispatch(searchCommunities({ page: 1, term: newValue }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error searching communities:', error);
      }
    } else if (newValue.length === 0) {
      updateAvailableCommunityTags(initialCommunityTags, selectedCommunityTags);
    }
  };

  return {
    selectedCommunityTags,
    setSelectedCommunityTags,
    communityTags: availableCommunityTags,
    inputCommunityTags,
    setInputCommunityTags,
    removeCommunity,
    addCommunity,
    filterCommunities
  };
};
