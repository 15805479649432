import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state';
import { SearchField3 } from '../../components/core/search-field';
import { getSearchUrl, search } from '../../state/actions/searchActions';
import { ISearchType } from '../../types/search.type';
import { createLink } from '../../utils/helperFunctions';
import { LibraryActions } from '../../actions/library-actions';

export interface Props {
  className?: string;
  placeholder?: string;
}

export const SearchFieldContainer: React.FC<Props> = () => {
  const counts = useAppSelector((s) => s.library.counts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { type } = useParams<{ type: ISearchType }>();

  useEffect(() => {
    if (!counts) {
      LibraryActions.loadCounts(dispatch);
    }
  });

  function handleSearch(q: string, searchType: ISearchType) {
    if (!q) {
      dispatch(
        search({
          term: '',
          pageNum: 1,
          searchType: ISearchType.Papers
        })
      );
      navigate(createLink('/'));
    } else {
      const url = getSearchUrl(q, searchType, 1);
      navigate(url);
    }
  }

  return (
    <>
      <SearchField3
        initialType={type}
        onSearch={handleSearch}
        counts={counts ? counts : undefined}
      />
    </>
  );
};
