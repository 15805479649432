import React from 'react';
import { Flex, HeadingOne } from '../../atoms';
import {
  selectPapers,
  selectPapersLoading
} from '../../state/selectors/papersSelector';
import { useAppSelector } from '../../state';
import { Tooltip } from '../../components/core/tooltip';
import { QuestionMark } from '../../svgs';

interface TopicProps {
  topicName: string | null | undefined;
}

const TopicTitlePlace: React.FC<TopicProps> = ({ topicName }) => {
  const loading = useAppSelector(selectPapersLoading);
  const papersCount = useAppSelector(selectPapers).count;

  return (
    <main className="page-content pt-10 pb-8 px-4">
      <div className="max-w-3xl m-auto">
        <Flex direction="flex-row w-full">
          <Flex>
            <HeadingOne color="black" className="mb-0">
              {topicName}
              {papersCount && !loading ? (
                <Tooltip
                  className="tooltipTitle"
                  text={papersCount + ' papers in the topic'}
                >
                  <QuestionMark />
                </Tooltip>
              ) : (
                ''
              )}
            </HeadingOne>
            <div className="text-primary-500 text-sm">#T[{topicName}]</div>
          </Flex>
        </Flex>
      </div>
    </main>
  );
};

export default TopicTitlePlace;
