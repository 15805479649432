import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiDiscussionsPaged } from '../../api/oipub-api';
import { env } from '../../env';
import { IDiscussionsPaged } from '../../types/discussions.type';

export const getDiscussionsByTopicUrlKey = createAsyncThunk<
  IDiscussionsPaged,
  { page: number; pageSize: number; urlKey: string; sortMethod?: string }
>(
  'get/getDiscussionsByTopicUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod },
    { rejectWithValue, signal }
  ) => {
    const query = `p=${page}&s=${pageSize}&order=${sortMethod || ''}`;
    const url = `${env.apiUrl}/api/v1/topics/${urlKey}/discussions?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        throw new Error('Failed to fetch discussions for the topic URL key.');
      }

      const data: ApiDiscussionsPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch discussions for the topic URL key.'
      );
    }
  }
);

export const getDiscussionsByCommunityUrlKey = createAsyncThunk<
  IDiscussionsPaged,
  { page: number; pageSize: number; urlKey: string; sortMethod?: string }
>(
  'get/getDiscussionsByCommunityUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod },
    { rejectWithValue, signal }
  ) => {
    const query = `p=${page}&s=${pageSize}&order=${sortMethod || ''}`;
    const url = `${env.apiUrl}/api/v1/communities/${urlKey}/discussions?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        throw new Error(
          'Failed to fetch discussions for the community URL key.'
        );
      }

      const data: ApiDiscussionsPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch discussions for the community URL key.'
      );
    }
  }
);
