import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { analytics } from '../../analytics';
import { TopicApi } from '../../api';
import { ApiUserPostPaged } from '../../api/oipub-api';
import { Loading } from '../../components/core';
import { Button } from '../../components/form';
import { UserPostPreview } from '../../components/userPost';
import { Pencil } from '../../svgs';
import { createLink } from '../../utils/helperFunctions';
import { useAuth } from '../../state/hooks/useAuth';

interface Props {}

export const TopicPostsPage: FC<Props> = () => {
  const { name } = useParams();
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<ApiUserPostPaged | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!name && !loading && !posts) {
      (async function () {
        try {
          setLoading(true);
          const apiPosts = await TopicApi.getPosts(name);
          setPosts(apiPosts);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [loading, name, posts]);

  const handleLogin = () => {
    analytics.events.loginCta('join_discussion');
    navigate(`/login?${qs.stringify({ redirect: location.pathname })}`);
  };

  if (loading || posts === null) {
    return <Loading />;
  }

  return (
    <>
      <div className="mb-5">
        {posts.count === 0 && (
          <div className="my-5">
            There are no discussions for this topic just yet.
          </div>
        )}
        {posts?.page &&
          posts.page.map((p) => <UserPostPreview key={p.id} post={p} />)}
      </div>
      {!!user ? (
        <Link to={createLink('/post/new')}>
          <Button>Start a new discussion</Button>
        </Link>
      ) : (
        <Button outline={true} onClick={handleLogin}>
          <span className="inline-block w-4 h-4 mr-1">
            <Pencil />
          </span>
          <span>Sign up to join the discussion</span>
        </Button>
      )}
    </>
  );
};
