import { SortingButtons } from '../../components/sortingButtons';
import { ViewModeToggle } from '../../components/viewModeToggle';
import {
  setTopicSortMethod,
  useAppDispatch,
  useAppSelector
} from '../../state';
import {
  TOPIC_SORT_METHODS,
  TopicSortMethod
} from '../../utils/sortingConstants';
import TopicsList from './topics-list';

export const TopicsListContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const topicSortMethod = useAppSelector((state) => state.sort.topicSortMethod);
  const handleSortingTopicChange = (method: TopicSortMethod) => () => {
    dispatch(setTopicSortMethod(method));
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <SortingButtons
          sortMethods={TOPIC_SORT_METHODS}
          currentSortMethod={topicSortMethod}
          onSortMethodChange={handleSortingTopicChange}
        />
        <ViewModeToggle />
        <TopicsList />
      </div>
    </div>
  );
};
