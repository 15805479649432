import { createAsyncThunk } from '@reduxjs/toolkit';
import { IComment } from '../../types/comment.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';

// AsyncThunk for fetching comments without deleted comments
export const getComments = createAsyncThunk<
  IComment[],
  { commentSectionId: number; order?: string }
>(
  'get/getComments',
  async ({ commentSectionId, order }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/commentsections/${commentSectionId}?order=${order || ''}`;
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }

      const data = await response.json();
      return data as IComment[];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An error occurred while fetching the comments');
      }
    }
  }
);

// AsyncThunk for fetching comments with deleted(without nested) comments
export const getCommentsWithDeleted = createAsyncThunk<
  IComment[],
  { commentSectionId: number; order?: string }
>(
  'get/getCommentsWithDeleted',
  async ({ commentSectionId, order }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/commentsections/${commentSectionId}/with-deleted-marks?order=${order || ''}`;
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }

      const data = await response.json();
      return data as IComment[];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An error occurred while fetching the comments');
      }
    }
  }
);

// AsyncThunk for posting a new comment
export const postComment = createAsyncThunk<
  void,
  { commentSectionId: number; body: string; parentCommentId: number | null }
>(
  'post/postComment',
  async ({ commentSectionId, body, parentCommentId }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/commentsections/${commentSectionId}`;
      const response = await sendProtectedRequest(url, {
        method: 'POST',
        body: {
          body,
          parentCommentId
        }
      });

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to post comment');
    }
  }
);

// AsyncThunk for editing an existing comment
export const editComment = createAsyncThunk<
  void,
  { commentId: number; body: string }
>('put/editComment', async ({ commentId, body }, { rejectWithValue }) => {
  try {
    const url = `${env.apiUrl}/api/v1/commentsections/comments/${commentId}`;
    const response = await sendProtectedRequest(url, {
      method: 'PUT',
      body: { body }
    });

    if (!response.ok) {
      throw new Error('Failed to edit comment');
    }
  } catch (error) {
    return rejectWithValue('Failed to edit comment');
  }
});

// AsyncThunk for deleting an existing comment
export const deleteComment = createAsyncThunk<void, { commentId: number }>(
  'delete/deleteComment',
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/commentsections/comments/${commentId}/soft`;

      // Make a DELETE request using sendProtectedRequest
      const response = await sendProtectedRequest(url, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to delete comment');
    }
  }
);
