import React from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import { setCommunitySortMethod } from '../../state/actions/sortingActions';
import {
  COMMUNITY_SORT_METHODS,
  CommunitySortMethod
} from '../../utils/sortingConstants';
import { SortingButtons } from '../../components/sortingButtons';
import { ViewModeToggle } from '../../components/viewModeToggle';
import CommunitiesList from './communities-list';

interface CommunityListContainerProps {}

export const CommunityListContainer: React.FC<
  CommunityListContainerProps
> = ({}) => {
  const dispatch = useAppDispatch();
  const communitySortMethod = useAppSelector(
    (state) => state.sort.communitySortMethod
  );

  const handleSortingCommunityChange = (method: CommunitySortMethod) => () => {
    dispatch(setCommunitySortMethod(method));
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <SortingButtons
          sortMethods={COMMUNITY_SORT_METHODS}
          currentSortMethod={communitySortMethod}
          onSortMethodChange={handleSortingCommunityChange}
        />
        <ViewModeToggle />
        <CommunitiesList />
      </div>
    </div>
  );
};

export default CommunityListContainer;
