import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '../../components/form';

interface Props {
  pageNum: number;
  numPages: number;
  getPageLink(_page: number): string;
}

export const Pager: FC<Props> = ({ pageNum, numPages, getPageLink }: Props) => {
  let pages = 4;
  let minPage = pageNum;
  let maxPage = pageNum;

  let lastPages = pages + 1;
  while (pages > 0 && pages < lastPages) {
    lastPages = pages;
    if (minPage > 2) {
      minPage--;
      pages--;
    }
    if (maxPage < numPages) {
      maxPage++;
      pages--;
    }
  }

  const showFirst = pageNum > 1 && minPage > 1;
  const showPrevious = pageNum > 1;
  const showNext = pageNum < numPages;

  function renderButton(link: string, text: string, active: boolean) {
    return (
      <NavLink key={link} to={link}>
        <Button circle={true} className="mx-2" outline={!active}>
          {text}
        </Button>
      </NavLink>
    );
  }

  const renderFirst = () => renderButton(getPageLink(1), '1', false);

  const renderPrevious = () =>
    renderButton(getPageLink(pageNum - 1), '<', false);

  const renderNext = () => renderButton(getPageLink(pageNum + 1), '>', false);

  function renderNearRange() {
    const pages = [];
    for (let i = minPage; i <= maxPage; i++) {
      // TODO fix this
      // eslint-disable-next-line testing-library/render-result-naming-convention
      const button = renderButton(getPageLink(i), i.toString(), i === pageNum);
      pages.push(button);
    }
    return <>{pages}</>;
  }

  return (
    <>
      {showPrevious && renderPrevious()}
      {showFirst && renderFirst()}
      {renderNearRange()}
      {showNext && renderNext()}
    </>
  );
};
