import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ITopicSummaryPaged } from '../../types/topics.type';
import { ICommonHubPaged } from '../../types/hubs.type';

export const getTopicsData = createAsyncThunk<
  ITopicSummaryPaged,
  { page: number; pageSize: number; sortMethod?: string }
>(
  'get/getTopicsData',
  async ({ page, pageSize, sortMethod }, { rejectWithValue }) => {
    const query =
      `&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/topics?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch topics');
    }
  }
);

export const getLandingTopicsData = createAsyncThunk<
  ITopicSummaryPaged,
  { page: number; pageSize: number; sortMethod?: string }
>(
  'get/getLandingTopicsData',
  async ({ page, pageSize, sortMethod }, { rejectWithValue }) => {
    const query =
      `&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/topics?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch topics');
    }
  }
);

export const searchTopics = createAsyncThunk<
  ICommonHubPaged,
  {
    term: string;
    page: number;
    abortController?: AbortController;
  },
  { rejectValue: string }
>(
  'search/searchTopics',
  async ({ term, page, abortController }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/topics/search?q=${encodeURIComponent(term)}&pageNum=${page}`;
    const signal = abortController?.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || 'Failed to fetch topics');
      }

      const data: ICommonHubPaged = await response.json();
      return data;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return rejectWithValue('Request was aborted');
      }
      return rejectWithValue(error.message || 'Failed to fetch topics');
    }
  }
);
