import { useState, useEffect, FormEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { selectTopicsSearchResults } from '../../../../state/selectors/topicsSelector';
import { Tag } from '../../updatePostV2/updatePostContainer';
import { notEmpty } from '../../../../utils/helperFunctions';
import { searchTopics } from '../../../../state/actions/topicsActions';
import { useGetTopicsData } from '../../../topic-v2/hooks/useGetTopicsData';

export const useTopicsFormData = () => {
  const { topics } = useGetTopicsData({
    page: 1,
    pageSize: 10,
    sortMethod: 'priority_desc'
  });
  const dispatch = useAppDispatch();
  const searchResultsTopics = useAppSelector(selectTopicsSearchResults);
  const [selectedTopicTags, setSelectedTopicTags] = useState<Tag[]>([]);
  const [availableTopicTags, setAvailableTopicTags] = useState<Tag[]>([]);
  const [initialTopicTags, setInitialTopicTags] = useState<Tag[]>([]);
  const [searchResultsTopicTags, setSearchResultsTopicTags] = useState<Tag[]>(
    []
  );
  const [inputTopicsValue, setInputTopicsValue] = useState('');

  const { selectedCommunityOrTopic } = useAppSelector(
    (state) => state.modal.createPost
  );
  const [isItitialTopicManuallyRemoved, setIsItitialTopicManuallyRemoved] =
    useState(false);

  // Fetch and set all topic tags when topics data changes
  useEffect(() => {
    const fullTopicTags =
      topics?.page
        ?.map((topic) => ({
          name: topic?.name || '',
          urlKey: topic?.urlKey || ''
        }))
        .filter(notEmpty) || [];
    setInitialTopicTags(fullTopicTags);
    updateAvailableTopicTags(fullTopicTags, selectedTopicTags);
  }, [topics, selectedTopicTags]);

  // Add the selectedCommunityOrTopic to selectedTopicTags if it's a topic
  useEffect(() => {
    if (
      selectedCommunityOrTopic?.entityType === 'topic' &&
      !isItitialTopicManuallyRemoved
    ) {
      const { name, urlKey } = selectedCommunityOrTopic;

      // Add only if it's not already selected
      if (!selectedTopicTags.some((tag) => tag.urlKey === urlKey)) {
        setSelectedTopicTags((prev) => [...prev, { name, urlKey }]);
      }
    }
  }, [
    selectedCommunityOrTopic,
    selectedTopicTags,
    isItitialTopicManuallyRemoved
  ]);

  // Update search results when searchResultsTopics changes
  useEffect(() => {
    if (searchResultsTopics?.page) {
      const searchedTopicTags = searchResultsTopics.page
        .map((topic) => ({
          name: topic?.name || '',
          urlKey: topic?.urlKey || ''
        }))
        .filter(notEmpty);
      setSearchResultsTopicTags(searchedTopicTags || []);
      if (inputTopicsValue.length >= 3) {
        updateAvailableTopicTags(searchedTopicTags, selectedTopicTags);
      }
    }
  }, [searchResultsTopics, selectedTopicTags, inputTopicsValue]);

  // Update available topics to exclude already selected topics
  const updateAvailableTopicTags = (allTags: Tag[], selectedTags: Tag[]) => {
    setAvailableTopicTags(
      allTags.filter(
        (tag) =>
          !selectedTags.some((selectedTag) => selectedTag.urlKey === tag.urlKey)
      )
    );
  };

  // Remove a topic from selected topics and add it back to the appropriate list
  const removeTopic = (topicUrlKey: string) => {
    const newList = selectedTopicTags.filter(
      (topic) => topic.urlKey !== topicUrlKey
    );
    setSelectedTopicTags(newList);

    if (selectedCommunityOrTopic?.urlKey === topicUrlKey) {
      setIsItitialTopicManuallyRemoved(true);
    }

    if (inputTopicsValue.length >= 3) {
      // When in search mode, update available topics based on search results
      updateAvailableTopicTags(searchResultsTopicTags, newList);
    } else {
      // When not in search mode, update available topics based on initial load
      updateAvailableTopicTags(initialTopicTags, newList);
    }
  };

  // Add a topic to selected topics and remove it from the appropriate list
  const addTopic = (topicUrlKey: string) => {
    const topicToAdd = availableTopicTags.find(
      (topic) => topic.urlKey === topicUrlKey
    );
    if (
      topicToAdd &&
      !selectedTopicTags.some((topic) => topic.urlKey === topicUrlKey)
    ) {
      setSelectedTopicTags([topicToAdd, ...selectedTopicTags]);
      setAvailableTopicTags((prev) =>
        prev.filter((tag) => tag.urlKey !== topicUrlKey)
      );
    }
  };

  // Filter topics based on input value
  const filterTopics = async (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setInputTopicsValue(newValue);

    if (newValue.length >= 3) {
      try {
        await dispatch(searchTopics({ page: 1, term: newValue }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error searching topics:', error);
      }
    } else if (newValue.length === 0) {
      updateAvailableTopicTags(initialTopicTags, selectedTopicTags);
    }
  };

  return {
    selectedTopicTags,
    setSelectedTopicTags,
    topicTags: availableTopicTags,
    inputTopicsValue,
    setInputTopicsValue,
    removeTopic,
    addTopic,
    filterTopics
  };
};
