import { FC, useCallback } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SearchPagination } from '../../routes/search/search-pagination';
import { DiscussionSortMethod } from '../../utils/sortingConstants';
import { Loading } from '../core';
import { useGetDiscussionsByTopicUrlKeyData } from './hooks/useGetDiscussionsByTopicUrlKeyData';
import { IDiscussionsPage } from '../../types/discussions.type';
import { useAppDispatch, useAppSelector } from '../../state';
import { openModal } from '../../state/actions/modalsActions';
import { useNavigate } from 'react-router-dom';
import { ErrorComponent } from '../core/error';
import DiscussionPaperSummary from '../../routes/topic-v2/discussionPaperSummary';
import { selectTopic } from '../../state/selectors/topicSelector';
import { ISelectedEntity } from '../../types/topic.type';
import { useAuth } from '../../state/hooks/useAuth';

interface DiscussionTopicListProps {
  urlKey?: string;
  updatePage: (newPage: number) => void;
  isTripleView: boolean;
  currentDiscussionPage: number;
  discussionSortMethod: DiscussionSortMethod;
}

export const DiscussionTopicList: FC<DiscussionTopicListProps> = ({
  urlKey,
  updatePage,
  isTripleView,
  currentDiscussionPage,
  discussionSortMethod
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const topic = useAppSelector(selectTopic);
  const { discussions, loading, error } = useGetDiscussionsByTopicUrlKeyData({
    page: currentDiscussionPage,
    pageSize: 10,
    urlKey: urlKey ?? '',
    sortMethod: discussionSortMethod
  });

  const handleOpenModalCreate = () => {
    dispatch(
      openModal('createPost', '', {
        name: topic?.name,
        urlKey: topic?.urlKey,
        entityType: 'topic'
      } as ISelectedEntity)
    );
  };

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openSignupModal = useCallback(
    () => handleOpenModalHash('signup'),
    [handleOpenModalHash]
  );

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (discussions && discussions.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="w-full max-w-3xl">
          <p>No discussions yet.</p>
          <p>
            Start a conversation!{' '}
            <button
              className="text-primary-500 font-semibold"
              onClick={user ? handleOpenModalCreate : openSignupModal}
              type="button"
            >
              Click here to create a new post.
            </button>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex flex-col  items-center h-auto  w-full py-3 mb-2 px-4">
      <div className="flex w-full flex  max-w-3xl ">
        <div className="flex flex-wrap  w-full">
          {discussions && discussions.page && discussions.page.length > 0 && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                600: isTripleView ? 2 : 1,
                900: isTripleView ? 3 : 1
              }}
              className="w-full"
            >
              <Masonry gutter="14px">
                {discussions?.page?.map(
                  (discussion: IDiscussionsPage, idx: number) => (
                    <DiscussionPaperSummary
                      key={`DiscussionSummaryv2${idx}`}
                      showThreeItems={isTripleView}
                      discussion={discussion}
                    />
                  )
                )}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </div>
      <p className="mt-8 text-sm">
        Start a conversation!{' '}
        <button
          className="text-primary-500 font-semibold"
          onClick={user ? handleOpenModalCreate : openSignupModal}
          type="button"
        >
          Click here to create a new post.
        </button>
      </p>

      {discussions?.numPages && currentDiscussionPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentDiscussionPage}
            lastPage={discussions?.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};
