/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IComment } from '../types/comment.type';
import { IUserProfileIsAdmin } from '../types/user.type';
import { ApiUserPostPreview } from './models/api-user-post';

export interface ApiAuthorRef {
  name?: string | null;
}

export interface ApiCommonHub {
  refType?: HubType;
  /** @format int32 */
  refId?: number;
  name?: string | null;
  description?: string | null;
  urlKey?: string | null;
  /** @format int32 */
  paperListId?: number | null;
  state?: HubState;
  /** @format date-time */
  createdUtc?: string | null;
  /** @format date-time */
  lastModifiedUtc?: string | null;
}

export interface ApiCommonHubPaged {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  numPages?: number;
  /** @format int32 */
  pageNum?: number;
  page?: ApiCommonHub[] | null;
}

export interface ApiCommunity {
  name?: string | null;
  urlKey?: string | null;
  description?: string | null;
  type?: ApiCommunityType;
  topicTags?: ApiTopicResponse[] | null;
}

export interface ApiCommunitySummary {
  name?: string | null;
  description?: string | null;
  urlKey?: string | null;
  type?: ApiCommunityType;
  /** @format int32 */
  papersCount?: number;
  /** @format int32 */
  postsCount?: number;
}

export interface ApiCommunitySummaryPaged {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  numPages?: number;
  /** @format int32 */
  pageNum?: number;
  page?: ApiCommunitySummary[] | null;
}

/** @format int32 */
export enum ApiCommunityType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2
}

export interface ApiCreateCommentRequest {
  /** @format int32 */
  parentCommentId?: number | null;
  /** @minLength 1 */
  body: string;
}

export interface ApiCreateCommunityRequest {
  name?: string | null;
  description?: string | null;
  topicTags?: string[] | null;
}

export interface ApiCreateTopicRequest {
  name?: string | null;
}

export interface ApiCreateUserPostRequest {
  title?: string | null;
  markdown?: string | null;
  topics?: string[] | null;
  communities?: string[] | null;
}

export interface ApiDatabaseCounts {
  /** @format int64 */
  numPapers?: number;
  /** @format int64 */
  numTopics?: number;
  /** @format int64 */
  numCommunities?: number;
  /** @format int64 */
  numAuthors?: number;
}

export interface ApiForgotPasswordRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  host?: string;
}

export interface ApiLibraryVersion {
  /** @format int32 */
  id?: number;
  service?: string | null;
  identifier?: string | null;
  /** @format date-time */
  startedUtc?: string;
  /** @format date-time */
  completedUtc?: string | null;
  /** @format date-time */
  lastUpdatedUtc?: string | null;
  /** @format date-time */
  snapshotUtc?: string | null;
  isCompleted?: boolean;
  /** @format int32 */
  numChanges?: number;
}

export interface ApiLoginRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  password: string;
}

export interface ApiLoginResponse {
  jwt?: string | null;
  /** @format date-time */
  expiry?: string;
  user?: ApiLoginUser;
}

export interface ApiLoginUser {
  /** @format int32 */
  id?: number;
  emailAddress?: string | null;
  displayName?: string | null;
}

export interface ApiNotification {
  /** @format int32 */
  notificationScopeId?: number | null;
  /** @format int32 */
  userId?: number;
  /** @format int32 */
  id?: number;
  title?: string | null;
  content?: string | null;
  /** @format date-time */
  createdUtc?: string;
  isRead?: boolean;
  /** @format date-time */
  readUtc?: string | null;
  isNew?: boolean;
  type?: string | null;
  priority?: string | null;
}

export interface ApiPaper {
  id?: string | null;
  doi?: string | null;
  title?: string | null;
  /** @format int32 */
  publishedYear?: number;
  /** @format int32 */
  publishedMonth?: number | null;
  publishedDay?: number | null;
  authors?: ApiAuthorRef[] | null;
  journal?: ApiPaperJournal | null;
  abstract?: string | null;
  links?: ApiPaperLink[] | null;
  /** @format int32 */
  commentSectionId?: number | null;
  tags?: ApiTopicResponse[] | null;
}

export interface ApiPaperJournal {
  title: string | null;
  publisher: string | null;
  issue: string | null;
  volume: string | null;
}

export interface ApiPaperPreview {
  id: string;
  title: string;
  authors: ApiAuthorRef[];
  publishedYear: number;
  publishedMonth: number | null;
  links: ApiPaperLink[];
  numComments: number;
  mostRecentComment: IComment | null;
  abstract?: string | null;
  keywords?: ApiTopicTag[] | null | undefined;
  tags?: ApiTopicResponse[] | null;
}

export interface ApiPaperLink {
  url?: string | null;
  type?: string | null;
  contentType?: string | null;
}

export interface ApiPaperPaged {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  numPages?: number;
  /** @format int32 */
  pageNum?: number;
  page?: ApiPaper[] | null;
}

export interface ApiReferral {
  referrer?: ApiUser;
  /** @format date-time */
  sentUtc?: string;
}

export interface ApiRegisterRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  password: string;
  researchRole?: string | null;
  /** @format int32 */
  researchYears?: number | null;
  referralCode?: string | null;
}

export interface ApiResetPasswordRequest {
  /** @minLength 1 */
  token: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  newPassword: string;
}

export interface ApiSendReferralRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  name: string;
  message?: string | null;
}

export interface ApiTopic {
  name?: string | null;
  urlKey?: string | null;
}

export interface ApiTopicResponse {
  id?: string | null;
  name?: string | null;
  urlKey?: string | null;
}

export interface ApiCommunityResponse {
  id?: string | null;
  name?: string | null;
  urlKey?: string | null;
}

export interface ApiTopicSummary {
  name?: string | null;
  urlKey?: string | null;
  /** @format int32 */
  papersCount?: number;
  papersWithCommentsCount?: number;
  /** @format int32 */
  postsCount?: number;
  postsWithCommentsCount?: number;
}

export interface ApiTopicSummaryPaged {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  numPages?: number;
  /** @format int32 */
  pageNum?: number;
  page?: ApiTopicSummary[] | null;
}

export interface ApiTopicTag {
  /** @format int32 */
  id?: number;
  tag?: string | null;
  /** @format int32 */
  paperListId?: number | null;
  state?: ApiTopicTagState;
  /** @format date-time */
  createdUtc?: string;
  /** @format date-time */
  lastModifiedUtc?: string | null;
}

/** @format int32 */
export enum ApiTopicTagState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2
}

export interface ApiUser {
  /** @format int32 */
  id?: number;
  displayName?: string | null;
}

export interface ApiUserPost {
  /** @format int32 */
  id?: number;
  title?: string | null;
  /** @format int32 */
  userId?: number;
  /** @format date-time */
  createdUtc?: string;
  /** @format date-time */
  lastModifiedUtc?: string | null;
  markdown?: string | null;
  /** @format int32 */
  commentSectionId?: number | null;
  tags?: ApiTopicResponse[];
  communities?: ApiCommunityResponse[];
}

export interface ApiUserPostPaged {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  numPages?: number;
  /** @format int32 */
  pageNum?: number;
  page?: ApiUserPost[] | null;
}

export interface ApiUserProfile {
  /** @format int32 */
  id?: number;
  displayName?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  /** @format date-time */
  createdUtc?: string;
  /** @format date-time */
  lastUpdatedUtc?: string;
  researchRole?: string | null;
  /** @format int32 */
  researchYears?: number | null;
  bio?: string | null;
  profileImageUrl?: string | null;
  socialMedias?: ApiUserSocialMedia[] | null;
}

export interface ApiUserSocialMedia {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  userId?: number;
  /** @format int32 */
  socialMediaId?: number;
  link?: string | null;
  /** @format date-time */
  lastUpdatedUtc?: string;
  title?: string | null;
  iconUrl?: string | null;
  regexValidator?: string | null;
}

export interface ApiUserUpdateRequest {
  displayName?: string | null;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  researchRole?: string | null;
  /** @format int32 */
  researchYears?: number | null;
  bio?: string | null;
  profileImageUrl?: string | null;
  socialMedias?: ApiUserSocialMedia[] | null;
}

export interface ApiVoteRequest {
  type?: ApiVoteType;
}

/** @format int32 */
export enum ApiVoteType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2
}

export interface EncodedPaperId {
  encoded?: string | null;
  /** @format int32 */
  id?: number;
}

/** @format int32 */
export enum HubState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2
}

/** @format int32 */
export enum HubType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2
}

/** @format int32 */
export enum SearchTypes {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export interface ApiFilterData {
  name?: string | null;
  browse?: string | null;
  order?: string | null;
  pappersMin?: number | null;
  pappersMax?: number | null;
  postsMin?: number | null;
  postsMax?: number | null;
  startWith?: string | null;
  broadcasting?: any[] | null;
  discussionType?: any[] | null;
  discussionTitle?: string | null;
  commentsMin?: number | null;
  commentsMax?: number | null;
  creationBefore?: string | null;
  creationAfter?: string | null;
  creator?: ApiAuthorRef[] | null;
  topicComm?: string | null;
}

export interface ApiDiscussionsPage {
  type: 'paper' | 'post';
  paper?: ApiPaper;
  userPost?: ApiUserPostPreview;
}

export interface ApiDiscussionsPaged {
  count: number;
  pageSize: number;
  numPages: number;
  pageNum: number;
  page: ApiDiscussionsPage[] | null;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body)
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OiPub API
 * @version v1
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Admin
     * @name V1AdminResetIndexCreate
     * @request POST:/api/v1/admin/reset-index
     */
    v1AdminResetIndexCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admin/reset-index`,
        method: 'POST',
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthLoginCreate
     * @request POST:/api/v1/auth/login
     */
    v1AuthLoginCreate: (data: ApiLoginRequest, params: RequestParams = {}) =>
      this.request<ApiLoginResponse, any>({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthForgotCreate
     * @request POST:/api/v1/auth/forgot
     */
    v1AuthForgotCreate: (
      data: ApiForgotPasswordRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/auth/forgot`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthResetCreate
     * @request POST:/api/v1/auth/reset
     */
    v1AuthResetCreate: (
      data: ApiResetPasswordRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/auth/reset`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name V1CommentsectionsCommentsVoteCreate
     * @request POST:/api/v1/commentsections/comments/{id}/vote
     */
    v1CommentsectionsCommentsVoteCreate: (
      id: number,
      data: ApiVoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/commentsections/comments/${id}/vote`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CommentSection
     * @name V1CommentsectionsDetail
     * @request GET:/api/v1/commentsections/{id}
     */
    v1CommentsectionsDetail: (
      id: number,
      query: { order?: string } = {},
      params: RequestParams = {}
    ) =>
      this.request<IComment[], any>({
        path: `/api/v1/commentsections/${id}`,
        method: 'GET',
        query: query, // Include the query object in the request
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CommentSection
     * @name V1CommentsectionsCreate
     * @request POST:/api/v1/commentsections/{id}
     */
    v1CommentsectionsCreate: (
      id: number,
      data: ApiCreateCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/api/v1/commentsections/${id}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Communities
     * @name V1CommunitiesList
     * @request GET:/api/v1/communities
     */
    v1CommunitiesList: (
      query?: {
        communityType?: ApiCommunityType;
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        order?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiCommunitySummaryPaged, any>({
        path: `/api/v1/communities`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Communities
     * @name V1CommunitiesCreate
     * @request POST:/api/v1/communities
     */
    v1CommunitiesCreate: (
      data: ApiCreateCommunityRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/communities`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * Update the name of a community
     *
     * @tags Communities
     * @name v1CommunitiesUpdateName
     * @request PUT:/api/v1/communities/{key}/name
     */
    v1CommunitiesUpdateName: (
      key: string,
      name: string,
      params: RequestParams = {}
    ) => {
      return this.request<void, any>({
        path: `/api/v1/communities/${key}/name`,
        method: 'PUT',
        body: JSON.stringify({ name }),
        type: ContentType.Json,
        //   format: 'json',
        ...params
      });
    },

    /**
     * Update the description of a community
     *
     * @tags Communities
     * @name v1CommunitiesUpdateDescription
     * @request PUT:/api/v1/communities/{key}/description
     */
    v1CommunitiesUpdateDescription: (
      key: string,
      description: string,
      params: RequestParams = {}
    ) => {
      return this.request<void, any>({
        path: `/api/v1/communities/${key}/description`,
        method: 'PUT',
        body: JSON.stringify({ description }),
        type: ContentType.Json,
        ...params
      });
    },

    /**
     * Update the topic tags of a community
     *
     * @tags Communities
     * @name v1CommunitiesUpdateTopics
     * @request PUT:/api/v1/communities/{key}/topic-tags
     */
    v1CommunitiesUpdateTopics: (
      key: string,
      topicTags: string[],
      params: RequestParams = {}
    ) => {
      return this.request<void, any>({
        path: `/api/v1/communities/${key}/topic-tags`,
        method: 'PUT',
        body: JSON.stringify({ topicTags }),
        type: ContentType.Json,
        ...params
      });
    },

    /**
     * Deletes a community based on the given key.
     *
     * @tags Communities
     * @name V1CommunitiesDelete
     * @request DELETE:/api/v1/communities/{key}
     */
    v1CommunitiesDelete: (key: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/communities/${key}`,
        method: 'DELETE',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name V1CommunitiesDetail
     * @request GET:/api/v1/communities/{key}
     */
    v1CommunitiesDetail: (key: string, params: RequestParams = {}) =>
      this.request<ApiCommunity, any>({
        path: `/api/v1/communities/${key}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name v1SearchCommunities
     * @request GET:/api/v1/communities/search
     */
    v1SearchCommunities: (
      query?: {
        q?: string;
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiCommunitySummaryPaged, any>({
        path: `/api/v1/communities/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name v1SearchTopics
     * @request GET:/api/v1/topics/search
     */
    v1SearchTopics: (
      query?: {
        q?: string;
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiTopicSummaryPaged, any>({
        path: `/api/v1/topics/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name V1CommunitiesPapersDetail
     * @request GET:/api/v1/communities/{key}/papers
     */
    v1CommunitiesPapersDetail: (
      key: string,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
        order?: string; // sorting order
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiPaperPaged, any>({
        path: `/api/v1/communities/${key}/papers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name V1CommunitiesPostsDetail
     * @request GET:/api/v1/communities/{key}/posts
     */
    v1CommunitiesPostsDetail: (
      key: string,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiUserPostPaged, any>({
        path: `/api/v1/communities/${key}/posts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Community
     * @name v1CommunitiesDiscussionsDetail
     * @request GET:/api/v1/communities/{key}/discussions
     */
    v1CommunitiesDiscussionsDetail: (
      key: string,
      query: {
        p?: number; // page number
        s?: number; // page size
        order?: string; // sorting order
      } = {},
      params: RequestParams = {}
    ) =>
      this.request<ApiDiscussionsPaged, any>({
        path: `/api/v1/communities/${key}/discussions`,
        method: 'GET',
        query: { ...query, order: query.order }, // include the order in the query
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Feed
     * @name V1FeedSearchList
     * @request GET:/api/v1/feed/search
     */
    v1FeedSearchList: (
      query?: {
        q?: string;
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiPaperPaged, any>({
        path: `/api/v1/feed/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Hubs
     * @name V1HubsSearchList
     * @request GET:/api/v1/hubs/search
     */
    v1HubsSearchList: (
      query?: {
        q?: string;
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
        t?: SearchTypes;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiCommonHubPaged, any>({
        path: `/api/v1/hubs/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Import
     * @name V1ImportDoajCreate
     * @request POST:/api/v1/import/doaj
     */
    v1ImportDoajCreate: (
      query?: {
        path?: string;
        /** @format date-time */
        lastUpdate?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/import/doaj`,
        method: 'POST',
        query: query,
        ...params
      }),

    /**
     * No description
     *
     * @tags Library
     * @name V1LibraryStatsList
     * @request GET:/api/v1/library/stats
     */
    v1LibraryStatsList: (params: RequestParams = {}) =>
      this.request<ApiDatabaseCounts, any>({
        path: `/api/v1/library/stats`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Library
     * @name V1LibraryVersionHistoryList
     * @request GET:/api/v1/library/version-history
     */
    v1LibraryVersionHistoryList: (params: RequestParams = {}) =>
      this.request<ApiLibraryVersion[], any>({
        path: `/api/v1/library/version-history`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags MyNotifications
     * @name V1MyNotificationsList
     * @request GET:/api/v1/my/notifications
     */
    v1MyNotificationsList: (params: RequestParams = {}) =>
      this.request<ApiNotification[], any>({
        path: `/api/v1/my/notifications`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags MyProfile
     * @name V1MyProfileUpdate
     * @request PUT:/api/v1/my/profile
     */
    v1MyProfileUpdate: (
      data: ApiUserUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/my/profile`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags MyProfile
     * @name V1MyProfile
     * @request GET:/api/v1/my/profile
     */
    v1MyProfile: (params: RequestParams = {}) =>
      this.request<IUserProfileIsAdmin, any>({
        path: `/api/v1/myprofile`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Paper
     * @name V1PapersDetail
     * @request GET:/api/v1/papers/{id}
     */
    v1PapersDetail: (id: number, params: RequestParams = {}) =>
      this.request<ApiPaper, any>({
        path: `/api/v1/papers/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Paper
     * @name V1PapersDetail2
     * @request GET:/api/v1/papers/{yearMonth}/{slug}
     * @originalName v1PapersDetail
     * @duplicate
     */
    v1PapersDetail2: (
      yearMonth: number,
      slug: string,
      params: RequestParams = {}
    ) =>
      this.request<ApiPaper, any>({
        path: `/api/v1/papers/${yearMonth}/${slug}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Paper
     * @name V1PapersDetail3
     * @request GET:/api/v1/papers/{yearMonth}/{slug}/{hash}
     * @originalName v1PapersDetail
     * @duplicate
     */
    v1PapersDetail3: (
      yearMonth: number,
      slug: string,
      hash: number,
      params: RequestParams = {}
    ) =>
      this.request<ApiPaper, any>({
        path: `/api/v1/papers/${yearMonth}/${slug}/${hash}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Paper
     * @name V1PapersVoteCreate
     * @request POST:/api/v1/papers/{id}/vote
     */
    v1PapersVoteCreate: (
      id: EncodedPaperId,
      data: ApiVoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/papers/${id}/vote`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Paper
     * @name V1PapersCommentsectionIdDetail
     * @request GET:/api/v1/papers/{id}/commentsection/id
     */
    v1PapersCommentsectionIdDetail: (
      id: EncodedPaperId,
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/api/v1/papers/${id}/commentsection/id`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Profiles
     * @name V1ProfilesDetail
     * @request GET:/api/v1/profiles/{userId}
     */
    v1ProfilesDetail: (userId: number, params: RequestParams = {}) =>
      this.request<ApiUserProfile, any>({
        path: `/api/v1/profiles/${userId}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Referral
     * @name V1ReferralCreate
     * @request POST:/api/v1/referral
     */
    v1ReferralCreate: (
      data: ApiSendReferralRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/referral`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Referral
     * @name V1ReferralDetail
     * @request GET:/api/v1/referral/{code}
     */
    v1ReferralDetail: (code: string, params: RequestParams = {}) =>
      this.request<ApiReferral, any>({
        path: `/api/v1/referral/${code}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Register
     * @name V1AccountRegisterCreate
     * @request POST:/api/v1/account/register
     */
    v1AccountRegisterCreate: (
      data: ApiRegisterRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/account/register`,
        method: 'POST',
        body: data,
        format: 'json',
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags Topic
     * @name V1TopicsDetail
     * @request GET:/api/v1/topics/{key}
     */
    v1TopicsDetail: (key: string, params: RequestParams = {}) =>
      this.request<ApiTopic, any>({
        path: `/api/v1/topics/${key}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Topic
     * @name V1TopicsPapersDetail
     * @request GET:/api/v1/topics/{key}/papers
     */
    v1TopicsPapersDetail: (
      key: string,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
        order?: string; // sorting order
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiPaperPaged, any>({
        path: `/api/v1/topics/${key}/papers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Topic
     * @name V1TopicsPostsDetail
     * @request GET:/api/v1/topics/{key}/posts
     */
    v1TopicsPostsDetail: (
      key: string,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        p?: number;
        /**
         * @format int32
         * @default 10
         */
        s?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiUserPostPaged, any>({
        path: `/api/v1/topics/${key}/posts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Topic
     * @name v1TopicsDiscussionsDetail
     * @request GET:/api/v1/topics/{key}/discussions
     */
    v1TopicsDiscussionsDetail: (
      key: string,
      query: {
        p?: number; // page number
        s?: number; // page size
        order?: string; // sorting order
      } = {},
      params: RequestParams = {}
    ) =>
      this.request<ApiDiscussionsPaged, any>({
        path: `/api/v1/topics/${key}/discussions`,
        method: 'GET',
        query: { ...query, order: query.order }, // include the order in the query
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Topics
     * @name V1TopicsList
     * @request GET:/api/v1/topics
     */
    v1TopicsList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number;
        order?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiTopicSummaryPaged, any>({
        path: `/api/v1/topics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags Topics
     * @name V1TopicsCreate
     * @request POST:/api/v1/topics
     */
    v1TopicsCreate: (data: ApiCreateTopicRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/topics`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags TopicTag
     * @name V1TopictagsDetail
     * @request GET:/api/v1/topictags/{tag}
     */
    v1TopictagsDetail: (tag: string, params: RequestParams = {}) =>
      this.request<ApiTopicTag, any>({
        path: `/api/v1/topictags/${tag}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags TopicTag
     * @name V1TopictagsUpdate
     * @request PUT:/api/v1/topictags/{tag}
     */
    v1TopictagsUpdate: (tag: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/topictags/${tag}`,
        method: 'PUT',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserPost
     * @name V1UserpostDetail
     * @request GET:/api/v1/userpost/{id}
     */
    v1UserpostDetail: (id: number, params: RequestParams = {}) =>
      this.request<ApiUserPost, any>({
        path: `/api/v1/userpost/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserPost
     * @name V1UserpostUpdate
     * @request PUT:/api/v1/userpost/{id}
     */
    v1UserpostUpdate: (
      id: number,
      data: ApiCreateUserPostRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/userpost/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags UserPost
     * @name V1UserpostVoteCreate
     * @request POST:/api/v1/userpost/{id}/vote
     */
    v1UserpostVoteCreate: (
      id: number,
      data: ApiVoteRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/userpost/${id}/vote`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags UserPost
     * @name V1UserpostCommentsectionIdDetail
     * @request GET:/api/v1/userpost/{id}/commentsection/id
     */
    v1UserpostCommentsectionIdDetail: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/api/v1/userpost/${id}/commentsection/id`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserPosts
     * @name V1UserpostsCreate
     * @request POST:/api/v1/userposts
     */
    v1UserpostsCreate: (
      data: ApiCreateUserPostRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/userposts`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
  };
  socialmedias = {
    /**
     * No description
     *
     * @tags Profiles
     * @name SocialmediasList
     * @request GET:/socialmedias
     */
    socialmediasList: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<ApiUserSocialMedia[], any>({
        path: `/socialmedias`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
}
