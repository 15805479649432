import { useEffect, useState } from 'react';
import {
  useCommunitiesFormData,
  usePostFormData,
  useTopicsFormData
} from './form';
import { useAppSelector } from '../../../state';
import { selectUserPost } from '../../../state/selectors/userPostSelector';
import { Tag } from '../updatePostV2/updatePostContainer';

export const usePostForm = () => {
  const post = useAppSelector(selectUserPost);

  const {
    postName,
    markdown,
    userCreatorId,
    onChangePostName,
    onChangeMarkdown
  } = usePostFormData();
  const {
    selectedTopicTags,
    setSelectedTopicTags,
    topicTags,
    inputTopicsValue,
    setInputTopicsValue,
    removeTopic,
    addTopic,
    filterTopics
  } = useTopicsFormData();
  const {
    selectedCommunityTags,
    setSelectedCommunityTags,
    communityTags,
    inputCommunityTags,
    setInputCommunityTags,
    removeCommunity,
    addCommunity,
    filterCommunities
  } = useCommunitiesFormData();

  const [errors, setErrors] = useState<Record<string, string>>({});

  // Get already selected topics from the post
  useEffect(() => {
    const initialTags: Tag[] =
      post?.tags?.map((tag) => ({
        name: tag.name ?? '',
        urlKey: tag.urlKey ?? ''
      })) || [];
    setSelectedTopicTags(initialTags);
  }, [post, setSelectedTopicTags]);

  // Get already selected communities from the post
  useEffect(() => {
    const initialCommunities: Tag[] =
      post?.communities?.map((tag) => ({
        name: tag.name ?? '',
        urlKey: tag.urlKey ?? ''
      })) || [];
    setSelectedCommunityTags(initialCommunities);
  }, [post, setSelectedCommunityTags]);

  return {
    postName,
    markdown,
    userCreatorId,
    selectedTopicTags,
    setSelectedTopicTags,
    selectedCommunityTags,
    setSelectedCommunityTags,
    errors,
    inputTopicsValue,
    setInputTopicsValue,
    inputCommunityTags,
    setInputCommunityTags,
    topicTags,
    communityTags,
    setErrors,
    onChangePostName,
    onChangeMarkdown,
    removeTopic,
    addTopic,
    removeCommunity,
    addCommunity,
    filterTopics,
    filterCommunities
  };
};
